import React, { useEffect } from 'react';
import Layout from '../components/Layout';
import RonakHero from '../components/RonakPipaliya/RonakHero';

import allApiData from "../api";
import PortfolioCom from '../components/Home/PortfolioCom';
import allData from '../allData';
import IndustriesCom from '../components/Home/IndustriesCom';

const somishExpertisesData = allData.vimalExpertisesData;

const userData = {
    name: "Somish Kakadiya",
    designation: "Chief Marketing Officer",
    image: '../assets/img/ronak-pipaliya/somish-kakadiya.png',
    tag: `somish-kakadiya`,
    phones: ['(+91) 9409017007'],
    // emails: ['vimal@vasundhara.io', 'vimal.vasundhara@gmail.com'],
    emails: ['somish@vasundhara.io'],
    linkedin: 'https://www.linkedin.com/in/somish-kakadiya',
    skype: 'https://join.skype.com/invite/ThIk9HM34qQg',
    behance: `https://www.behance.net/vasundharainfotech`,
    whatsapp: `https://wa.me/+919409017007`,
    description: [
        `Mr. Somish Kakadiya is an accomplished professional with a vision for innovation and business growth. `,
        `As a Founder and Chief Marketing Officer, he combines his expertise in product development, marketing, and a growth-oriented mindset to drive success and create products that resonate with users. `,
        `His dedication to fostering transparent communication and teamwork sets him apart as a forward-thinking leader in the industry.`,
        `Possesses a growth-oriented business mindset, dedicated to developing products that deliver tangible benefits to users (RTO).`,
        `Focused on product development with a keen understanding of user-friendly interfaces and visually appealing designs.`,
        `He is known for positively impacting company projects through a deep understanding of business dynamics.`,
        `He’s committed to transparent communication, consistently updating project progress to inspire continuous learning among team members.`,
        `He advocates for maintaining continuous engagement with team members, alleviating anxiety during times of uncertainty.`,
        `He regularly monitors the web development process and actively participates in issue troubleshooting during the development and testing phases.`,
    ]
}

function SomishKakadiya() {
    
    const tabNameData = ['App Development', 'Game Development', 'Website Development', 'Art & Animation'];
    useEffect(() => {
        allApiData.aosinit();
    }, []);

    return (
        <Layout footernone={false} padding={true} popup={true}>
            <div className='main-ronak'>
                <div className='ronak-hero'>
                    <RonakHero userData={userData} />
                </div>

                <div className="ronak-portfolio">
                    <PortfolioCom
                        heading="Wall of Pride"
                        isTabOn={true}
                        isImageComp={true}
                        isViewBtn={true}
                        // viewurl={`portfolio?type=website_development`}
                        viewurl={`portfolio`}
                        viewButton={'View the entire portfolio'}
                        tabData={tabNameData}
                        typeWise='app_type'
                        isPagination={false}
                        //portfolios={portFolio}
                    />
                </div>

                <div className="ronak-expertises">
                    <IndustriesCom h1="Expertises" industriesData={somishExpertisesData} buttonOn={false} bg={true} upperCase={true} />
                </div>
            </div>
        </Layout>
    )
}

export default SomishKakadiya